import { ButtonProps } from '@mui/material';
import { LoadingButton } from 'probonio-shared-ui/component/button';
import React from 'react';

interface Props extends ButtonProps {
  loading?: boolean;
  loadingVariant?: 'determinate' | 'indeterminate';
  loadingValue?: number;
  dataTestId?: string;
}

export const DialogLoadingButton = React.forwardRef<HTMLButtonElement, Props>(({ sx, ...props }, ref) => (
  <LoadingButton variant="text" size="small" {...props} ref={ref} sx={{ textTransform: 'uppercase', ...sx }} />
));
