import { Alert, Box } from '@mui/material';
import { DateTime } from 'luxon';
import {
  BenefitActivationDTO,
  BenefitDTOBenefitEnum,
  BenefitTemplateItemDTO,
  CreateBenefitActivationDTO,
  UpdateBenefitActivationDTO,
  UpdateCouponsBenefitOptionsDTO,
} from 'probonio-shared-ui/api';
import { apis, useTenantQuery } from 'probonio-shared-ui/module/api';
import { useTenant } from 'probonio-shared-ui/module/me';
import React, { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { SelectControl } from '../../../component/form';
import { fillNumberRangeArray } from '../../../util/numberRange';
import { ActivationDialogExtensions } from '../ActivationDialog';
import { NonCashLimitWarning } from '../ActivationDialog/NonCashLimitWarning';
import { FitnessFields } from '../fitness/FitnessFields';
import { AvailableCouponsAccordion } from './AvailableCouponsDrawer';

type BenefitOptions = UpdateCouponsBenefitOptionsDTO;
// TODO this value should be configurable per tax period (TaxConfig)
const MIN = 500;
const MAX = 5000;
const BUDGET_OPTIONS = fillNumberRangeArray(MIN, MAX, 500);
BUDGET_OPTIONS.push(4400);
BUDGET_OPTIONS.sort((a, b) => a - b);

const Header: React.FC = () => {
  const { t } = useTranslation('benefitsModule');
  const { tenant } = useTenant();
  const { watch } = useFormContext();
  const { employeeId } = useParams();

  const benefitOptions: BenefitOptions = watch('benefitOptions');

  const editMode = watch('editMode');

  const isAfterDeadline = tenant?.couponOrderDeadline && DateTime.now().day > tenant.couponOrderDeadline;

  const willExceedBudget = DateTime.now().day > 25 && (tenant?.couponBudget || 0) < benefitOptions.monthlyBudget;

  return (
    <>
      {isAfterDeadline && !editMode ? (
        <Alert severity="warning" sx={{ mb: 2 }}>
          {t('COUPONS.deadlineActivationWarning')}
        </Alert>
      ) : null}
      <NonCashLimitWarning employeeId={employeeId} benefit={BenefitDTOBenefitEnum.Coupons} monthlyBudget={benefitOptions.monthlyBudget} />
      {willExceedBudget && (
        <Alert severity="warning" sx={{ mb: 1 }}>
          {t('budgetExceededWarning')}
        </Alert>
      )}
    </>
  );
};

const Fields: React.FC = () => {
  const { watch } = useFormContext();

  const { data, isLoading } = useTenantQuery(['benefits', 'coupons', 'definition', apis.coupons.findCouponDefinitions.name], tenantId =>
    apis.coupons.findCouponDefinitions({ tenantId, sortBy: ['name:asc'] }).then(resp => resp.data),
  );

  const benefitOptions: BenefitOptions = watch('benefitOptions');

  return (
    <>
      {!isLoading && benefitOptions.monthlyBudget && (
        <>
          <Box mb={2}>
            <AvailableCouponsAccordion coupons={data?.coupons} amount={benefitOptions.monthlyBudget} />
          </Box>
          <FitnessFields />
        </>
      )}
    </>
  );
};

const InlineField: React.FC = () => {
  const { t, i18n } = useTranslation('benefitsModule');
  const { control } = useFormContext();

  return (
    <SelectControl
      control={control}
      name="benefitOptions.monthlyBudget"
      label={t('COUPONS.fields.monthlyBudget')}
      data-test-id="activate-dialog-monthly-budget"
      fullWidth
      margin="dense"
      rules={{ required: true }}
      options={BUDGET_OPTIONS.map(n => ({ value: n, label: i18n.format(n, 'euro-float') }))}
    />
  );
};

export function useCouponsDialogExtensions(): ActivationDialogExtensions<BenefitOptions> {
  const handleResetBenefitOptions = useCallback((selectedActivation?: BenefitActivationDTO | BenefitTemplateItemDTO): BenefitOptions => {
    return {
      monthlyBudget: selectedActivation?.couponsOptions
        ? selectedActivation.couponsOptions.monthlyBudget
        : BUDGET_OPTIONS[BUDGET_OPTIONS.length - 1],
      alternateBenefits: selectedActivation?.couponsOptions?.alternateBenefits ?? [],
    };
  }, []);

  const mapCouponsOptions = useCallback(
    (benefitOptions: BenefitOptions): UpdateCouponsBenefitOptionsDTO => ({
      monthlyBudget: benefitOptions.monthlyBudget,
      alternateBenefits: benefitOptions.alternateBenefits,
    }),
    [],
  );
  const handleCreateActivation = useCallback<ActivationDialogExtensions<BenefitOptions>['onCreateActivation']>(
    async (baseValues, benefitOptions, saveActivation) => {
      const create: CreateBenefitActivationDTO = {
        ...baseValues,
        benefit: BenefitDTOBenefitEnum.Coupons,
        couponsOptions: mapCouponsOptions(benefitOptions),
      };
      await saveActivation(create);
    },
    [mapCouponsOptions],
  );
  const handleUpdateActivation = useCallback<NonNullable<ActivationDialogExtensions<BenefitOptions>['onUpdateActivation']>>(
    async (baseValues, benefitOptions, saveActivation) => {
      const update: UpdateBenefitActivationDTO = {
        ...baseValues,
        couponsOptions: mapCouponsOptions(benefitOptions),
      };
      await saveActivation(update);
    },
    [mapCouponsOptions],
  );

  return useMemo(
    () => ({
      Header,
      Fields,
      InlineField,
      earliestStartDate: DateTime.now().plus({ months: DateTime.now().day > 25 ? 1 : 0 }),
      onResetBenefitOptions: handleResetBenefitOptions,
      onCreateActivation: handleCreateActivation,
      onUpdateActivation: handleUpdateActivation,
    }),
    [handleCreateActivation, handleResetBenefitOptions, handleUpdateActivation],
  );
}
