import Grid from '@mui/material/Grid2';
import { DateTime } from 'luxon';
import { BenefitDTOBenefitEnum } from 'probonio-shared-ui/api';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BenefitPaper } from '../../../component/benefitTabs/BenefitPaper';
import { AuthorizedLayout } from '../../navigation/AuthorizedLayout';
import { BENEFIT_ICONS } from '../BenefitIcon';
import BenefitSubPage from '../BenefitSubPage';
import { ChooseExportTypeListener } from './ChooseExportTypeListener';
import { isDate } from './isDate';
import { PayrollDownloadPanel } from './PayrollDownloadPanel';
import { PayrollExportAlerts } from './PayrollExportAlerts';
import { PayrollSettingsForm } from './PayrollSettingsForm';
import { useTenant } from 'probonio-shared-ui/module/me';

interface ExportInterval {
  start: Date;
  end: Date;
}

export type ExportDate = Date | ExportInterval;

const useReportBenefits = (benefit: BenefitDTOBenefitEnum, activeBenefits?: BenefitDTOBenefitEnum[]) => {
  const couponReportBenefits = useMemo(() => {
    const couponsBenefits: BenefitDTOBenefitEnum[] = [BenefitDTOBenefitEnum.Coupons, BenefitDTOBenefitEnum.Gifts];
    if (activeBenefits?.includes('FITNESS')) {
      couponsBenefits.push(BenefitDTOBenefitEnum.Fitness);
    }
    return couponsBenefits;
  }, [activeBenefits]);

  return useMemo(() => {
    switch (benefit) {
      case BenefitDTOBenefitEnum.Coupons:
        return couponReportBenefits;
      case BenefitDTOBenefitEnum.Flex:
        return [BenefitDTOBenefitEnum.Flex];
      default:
        return [benefit];
    }
  }, [benefit, couponReportBenefits]);
};

const BenefitPayrollReportPage: React.FC<{
  reportBenefit: BenefitDTOBenefitEnum;
  activeBenefits: BenefitDTOBenefitEnum[];
  menu?: React.ReactNode;
  showReceiptsWarning?: boolean;
}> = ({ reportBenefit, activeBenefits, menu, showReceiptsWarning }) => {
  const { t } = useTranslation('benefitsModule');
  const { tenant } = useTenant();
  const [exportDate, setExportDate] = useState<ExportDate>(new Date());

  const settingsBenefits = useReportBenefits(reportBenefit, tenant?.activeBenefits);

  const [isValid, setIsValid] = useState(true);

  const handleChangeExportMonth = useCallback((exportDate: ExportDate) => {
    setExportDate(
      isDate(exportDate)
        ? exportDate
        : {
            end: exportDate.end || new Date(),
            start: exportDate.start || DateTime.now().startOf('month').toJSDate(),
          },
    );
  }, []);

  const handleValidate = useCallback((isValid: boolean) => {
    setIsValid(isValid);
  }, []);

  return (
    <AuthorizedLayout
      title={t('subPage.payrollReport')}
      breadcrumbs={[{ title: t(`${reportBenefit}.title`), to: `/benefits/${reportBenefit.toLowerCase()}` }]}
    >
      <BenefitSubPage
        icon={BENEFIT_ICONS[reportBenefit]}
        title={t(`${reportBenefit}.title`)}
        subTitle={t('subPage.payrollReport')}
        menu={menu}
      >
        <PayrollExportAlerts exportDate={exportDate} showReceiptsWarning={showReceiptsWarning} />
        <Grid container spacing={2.5}>
          <Grid size={{ xs: 12, lg: 4 }}>
            <BenefitPaper>
              <PayrollDownloadPanel
                reportBenefit={reportBenefit}
                activeBenefits={activeBenefits}
                disabled={!isValid}
                onChangeExportMonth={handleChangeExportMonth}
                isInterval={!isDate(exportDate)}
              />
            </BenefitPaper>
          </Grid>
          <Grid size={{ xs: 12, lg: 8 }}>
            <BenefitPaper>
              <PayrollSettingsForm
                benefits={settingsBenefits}
                showBenefitHeaders={reportBenefit === BenefitDTOBenefitEnum.Flex}
                onValidate={handleValidate}
              />
            </BenefitPaper>
          </Grid>
        </Grid>
      </BenefitSubPage>
      <ChooseExportTypeListener benefits={activeBenefits} />
    </AuthorizedLayout>
  );
};

export default BenefitPayrollReportPage;
