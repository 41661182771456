import { BenefitActivationDTOBenefitEnum, FilterEmployeesDTO } from 'probonio-shared-ui/api';
import { useCallback, useState } from 'react';
import { ActivationDialog, useActivationDialogState } from '..';
import { useNewDialogState } from '../../../component/dialog';
import { RowSelectionState } from '../../../component/table/useManualRowSelection';
import { useEmployeeIdList } from '../../userManagement/useEmployeeIdList';
import { BulkEditActivationsDialog } from '../ActivationDialog/BulkEditActivationsDialog';
import { useBoundActivationComponent } from '../ActivationDialog/useBoundActivationComponent';
import { ApplyTemplateDialog } from '../ApplyTemplateDialog';
import { BenefitProvider } from '../BenefitContext';
import { BenefitRef } from '../BenefitsCell';
import { EmployeeFilter, mapEmployeeFilterToRequest } from '../employeeStatusList/employeeFilterState';
import { useBulkActivationSaveFunctions } from '../employeeStatusList/useBulkActivationSaveFunctions';
import { useBulkApplyTemplateMutation } from './useBulkApplyTemplateMutation';
import BulkBenefitActivationModalContext from './useBulkBenefitActivationModal';

interface Props {
  rowSelectionState: RowSelectionState;
  employeeFilter?: EmployeeFilter;
}

export const BulkBenefitActivationModalProvider: React.FC<React.PropsWithChildren<Props>> = ({
  rowSelectionState,
  employeeFilter,
  children,
}) => {
  const activationDialog = useActivationDialogState();
  const editDialog = useNewDialogState();
  const applyTemplateDialog = useNewDialogState();

  const [appliedTemplatesProgress, setAppliedTemplatesProgress] = useState<number>();

  const mappedEmployeeFilter: FilterEmployeesDTO = {
    ...mapEmployeeFilterToRequest(employeeFilter),
    considerFutureActivations: true,
    excludeEmployeeStatus: ['INACTIVE'],
  };

  const selectedEmployeeIds = Object.keys(rowSelectionState.rowSelection).filter(id => rowSelectionState.rowSelection[id]);

  const { data: allEmployeeIds } = useEmployeeIdList(
    { ...mappedEmployeeFilter },
    { enabled: rowSelectionState.allSelected && applyTemplateDialog.dialogState.isOpen },
  );

  const employeeIds = rowSelectionState.allSelected && allEmployeeIds?.employeeIds ? allEmployeeIds?.employeeIds : selectedEmployeeIds;

  const [benefitRef, setBenefitRef] = useState<BenefitRef | undefined>();

  const ActivationDialogComponent = useBoundActivationComponent(ActivationDialog, benefitRef?.benefit);
  const activationSaveFunctions = useBulkActivationSaveFunctions(rowSelectionState, employeeFilter);
  const { onApplyTemplate } = useBulkApplyTemplateMutation(rowSelectionState, setAppliedTemplatesProgress, employeeIds);

  const handleOpenActivationCreate = useCallback(
    (benefit: BenefitActivationDTOBenefitEnum, customBenefitId?: string) => {
      setBenefitRef({ benefit, customBenefitId });
      activationDialog.handleOpenDialogToCreate();
    },
    [activationDialog],
  );

  const handleOpenActivationEdit = useCallback(
    (benefit: BenefitActivationDTOBenefitEnum, customBenefitId?: string) => {
      setBenefitRef({ benefit, customBenefitId });
      editDialog.handleOpen();
    },
    [editDialog],
  );

  const handleOpenApplyTemplate = useCallback(() => {
    applyTemplateDialog.handleOpen();
  }, [applyTemplateDialog]);

  return (
    <BulkBenefitActivationModalContext.Provider
      value={{
        onBenefitCreate: handleOpenActivationCreate,
        onBenefitEdit: handleOpenActivationEdit,
        onApplyTemplate: handleOpenApplyTemplate,
      }}
    >
      <BenefitProvider benefit={benefitRef?.benefit!} customBenefitId={benefitRef?.customBenefitId} showLoadingIndicator={false}>
        <ActivationDialogComponent saveFunctions={activationSaveFunctions} activationDialogState={activationDialog} />
        <BulkEditActivationsDialog
          dialogState={editDialog.dialogState}
          employeeFilter={employeeFilter || {}}
          rowSelectionState={rowSelectionState}
        />
      </BenefitProvider>
      <ApplyTemplateDialog
        dialogState={applyTemplateDialog.dialogState}
        applyProgress={appliedTemplatesProgress}
        employeeIds={employeeIds}
        onApply={onApplyTemplate}
      />
      {children}
    </BulkBenefitActivationModalContext.Provider>
  );
};
