import { InfiniteData, useInfiniteQuery, UseInfiniteQueryResult } from '@tanstack/react-query';
import { FitnessApiGetTransactionHistoryRequest, FitnessTransactionsHistoryDTO } from 'probonio-shared-ui/api';
import { apis } from 'probonio-shared-ui/module/api';
import { useTenantID } from 'probonio-shared-ui/module/me';

const PAGE_SIZE = 10;

export function useInfiniteFitnessTransactionHistory(
  parameters?: (pageParam: number) => Partial<FitnessApiGetTransactionHistoryRequest>,
  options?: { enabled?: boolean },
): UseInfiniteQueryResult<InfiniteData<FitnessTransactionsHistoryDTO, Error>> {
  const getTenantId = useTenantID();

  return useInfiniteQuery({
    queryKey: [
      'tenants',
      getTenantId(),
      'benefits',
      'fitness',
      'transactionHistory',
      { pageSize: PAGE_SIZE, ...parameters?.(0) },
      apis.fitness.getTransactionHistory.name,
    ],
    queryFn: ({ pageParam = 0 }) =>
      apis.fitness
        .getTransactionHistory({
          tenantId: getTenantId(),
          page: pageParam,
          pageSize: PAGE_SIZE,
          ...parameters?.(pageParam),
        })
        .then(res => res.data),
    initialPageParam: 0,
    getNextPageParam: (lastPage, pages) => (pages.length * PAGE_SIZE < lastPage.totalCount ? pages.length : undefined),
    placeholderData: previousData => previousData,
    ...options,
  });
}
