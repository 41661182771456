import { Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { BrandLogo, NavbarLayout } from '.';
import { useMainDrawer } from '../../component/drawer/MainDrawerContext';
import { BreadcrumbLink } from './NavbarLayout';
import { UserMenu } from './UserMenu';
import { useTranslation } from 'react-i18next';
import { useTenant } from 'probonio-shared-ui/module/me';

interface Props {
  topPanel?: React.ReactNode;
  title?: string;
  breadcrumbs?: readonly BreadcrumbLink[];
}

export const AuthorizedLayout: React.FC<React.PropsWithChildren<Props>> = ({ topPanel, title, breadcrumbs, children }) => {
  const { t } = useTranslation('navigationModule');
  const { open, handleOpen: handleOpenDrawer } = useMainDrawer();
  const theme = useTheme();
  const { tenant } = useTenant();
  const isMobile = useMediaQuery(theme.breakpoints.down('xl'));

  return (
    <NavbarLayout
      logo={
        isMobile ? (
          <BrandLogo />
        ) : (
          <Stack>
            <Typography sx={{ verticalAlign: 'middle' }} component="span" variant="h2">
              {t('titleFull')}
            </Typography>
            {tenant?.name && (
              <Typography sx={{ verticalAlign: 'middle' }} variant="h3" component="span" color="textSecondary">
                {tenant.name}
              </Typography>
            )}
          </Stack>
        )
      }
      menu={<UserMenu />}
      title={title}
      breadcrumbs={breadcrumbs}
      topPanel={topPanel}
      onOpenDrawer={handleOpenDrawer}
      open={open}
    >
      {children}
    </NavbarLayout>
  );
};
