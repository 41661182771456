import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { ApplyBenefitTemplateDTO, BulkApplyBenefitTemplateDTO, BulkCreateResponseDTO, BulkResultItemDTO } from 'probonio-shared-ui/api';
import { apis } from 'probonio-shared-ui/module/api';
import { useTenantID } from 'probonio-shared-ui/module/me';
import { SetStateAction, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { RowSelectionState } from '../../../component/table/useManualRowSelection';
import { useRefetchEmployees } from '../../userManagement/query';
import { useRefetchActivations } from '../query';

export function useBulkApplyTemplateMutation(
  rowSelectionState: RowSelectionState,
  setAppliedTemplatesProgress: (value: SetStateAction<number | undefined>) => void,
  employeeIds: string[],
): { onApplyTemplate: (applyTemplate: ApplyBenefitTemplateDTO) => Promise<void> } {
  const { t } = useTranslation('benefitsModule');
  const getTenantId = useTenantID();
  const refetchEmployees = useRefetchEmployees();
  const refetchActivations = useRefetchActivations();
  const { enqueueSnackbar } = useSnackbar();

  const bulkApplyTemplateMutation = useMutation({
    mutationFn: (params: BulkApplyBenefitTemplateDTO) =>
      apis.benefits.bulkApplyBenefitTemplate({ tenantId: getTenantId(), bulkApplyBenefitTemplateDTO: params }),
  });

  const BATCH_SIZE = 20;

  const handleApplyTemplate = useCallback(
    async (applyTemplate: ApplyBenefitTemplateDTO) => {
      const chunkedArray =
        employeeIds.reduce((all, one, i) => {
          const ch = Math.floor(i / BATCH_SIZE);
          all[ch] = (all[ch] ?? []).concat(one);
          return all;
        }, [] as string[][]) || [];

      const processedEmployees: BulkResultItemDTO[] = [];

      for (const employeeIdsChunk of chunkedArray) {
        let processedEmployeeIdBatch: BulkCreateResponseDTO;
        do {
          processedEmployeeIdBatch = await bulkApplyTemplateMutation
            .mutateAsync({
              employeeIds: employeeIdsChunk,
              applyTemplate,
            })
            .then(res => res.data);
        } while (!processedEmployeeIdBatch);
        processedEmployees.push(...processedEmployeeIdBatch.results);
        setAppliedTemplatesProgress((processedEmployees.length / (employeeIds.length || 0)) * 100);
      }

      const successResults = processedEmployees.filter(result => result.status === 'SUCCESS');
      const errorResults = processedEmployees.filter(result => result.status === 'ERROR');
      if (processedEmployees.length === 0) {
        enqueueSnackbar(t('bulkApplyTemplate.noNewActivationsDone'), { variant: 'warning' });
      } else if (errorResults.length && successResults.length) {
        enqueueSnackbar(
          t('bulkApplyTemplate.newActivationsSomeErrors', { successCount: successResults.length, errorCount: errorResults.length }),
          { variant: 'warning' },
        );
      } else if (errorResults.length) {
        enqueueSnackbar(t('bulkApplyTemplate.newActivationsError', { count: errorResults.length }), { variant: 'error' });
      } else {
        enqueueSnackbar(t('bulkApplyTemplate.newActivationsSuccess', { count: successResults.length }), { variant: 'success' });
      }

      if (successResults.length) {
        rowSelectionState.handleReset();
      }
      await refetchEmployees();
      await refetchActivations();
      setAppliedTemplatesProgress(undefined);
    },
    [
      bulkApplyTemplateMutation,
      employeeIds,
      enqueueSnackbar,
      refetchActivations,
      refetchEmployees,
      rowSelectionState,
      setAppliedTemplatesProgress,
      t,
    ],
  );

  return useMemo(
    () => ({
      onApplyTemplate: handleApplyTemplate,
    }),
    [handleApplyTemplate],
  );
}
