import { Box, Button, Stack, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { BenefitDTOBenefitEnum, TenantDTOReportBenefitListEnum } from 'probonio-shared-ui/api';
import { apis } from 'probonio-shared-ui/module/api';
import { useTenant } from 'probonio-shared-ui/module/me';
import { validateEmail } from 'probonio-shared-ui/utils/email';
import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNewDialogState } from '../../../component/dialog';
import { TextFieldControl } from '../../../component/form';
import { CheckboxControl } from '../../../component/form/CheckboxControl';
import TextFieldChipsControl from '../../../component/form/TextFieldChipsControl';
import { SettingsFormPanel } from '../../../component/settings/SettingsFormPanel';
import { SettingsFormRow } from '../../../component/settings/SettingsFormRow';
import i18n from '../../../lang';
import { useAppSelector } from '../../../redux/hooks';
import { updateArrayAll } from './arrayUtils';
import { PayrollReportChooseExportTypeDialog } from './PayrollReportChooseExportTypeDialog';
import { ExportTypeFields, useExportTypeFields } from './payrollReportConfig';
import { SelectedExportTypeLogo } from './SelectedExportTypeLogo';

interface Props {
  benefits: BenefitDTOBenefitEnum[];
  showBenefitHeaders?: boolean;
  onValidate: (isValid: boolean) => void;
}

interface FormValues {
  tenantNumber: string | undefined;
  consultantNumber: string | undefined;

  salaryTypeTaxFree: string;
  salaryTypeTaxable?: string;
  salaryTypeSalaryReduction: string;
  salaryTypeMobilityTaxFree: string;
  salaryTypeMobilityTaxable: string;
  salaryTypeCoupons: string;
  salaryTypeGifts: string;
  salaryTypeIncentives: string;
  salaryTypeInternet: string;
  salaryTypeRecreation: string;
  salaryTypeFitness: string;

  enableAutomatedReports: boolean;
  enableDetailReports: boolean;
  reportEmailList: string[];
}

function validateEmails(value: string[]): string | true {
  if (value.length > 3) {
    return i18n.t('usersModule:createNewUserFields.notMoreThanThree');
  } else if (value.some(email => !validateEmail(email))) {
    return i18n.t('usersModule:createNewUserFields.notValidMail');
  } else if (value.length <= 0) {
    return i18n.t('common:inputRequired');
  }
  return true;
}

export const PayrollSettingsForm: React.FC<Props> = ({ benefits, showBenefitHeaders, onValidate }) => {
  const { t } = useTranslation('receiptsModule');
  const { tenant, invalidateTenant } = useTenant();
  const user = useAppSelector(state => state.me.user);

  const { control, handleSubmit, reset, watch, formState } = useForm<FormValues>({
    defaultValues: { enableAutomatedReports: false, enableDetailReports: false, reportEmailList: [] },
  });

  useEffect(() => {
    onValidate(formState.isValid && !formState.isDirty);
  }, [formState.isDirty, formState.isValid, onValidate]);

  // This checks if tenant mail and user mail are qual then return only user mail else return both
  const getTenantUserEmail = useCallback(() => {
    if (user?.mail === tenant?.mail) {
      return [user?.mail];
    }
    return [user?.mail, tenant?.mail];
  }, [tenant?.mail, user?.mail]);
  // This will get the tenant (and user) mail pre filed into email field
  const getDefaultEmailList = useCallback(() => {
    if (tenant && tenant.reportEmailList) {
      return tenant.reportEmailList.length > 0 ? tenant.reportEmailList : getTenantUserEmail();
    }
    return [];
  }, [tenant, getTenantUserEmail]);
  // This will generate the array for the tenant benefit report list

  useEffect(() => {
    if (!tenant) {
      return;
    }

    reset({
      consultantNumber: `${tenant?.lodasExportConsultant || ''}`,
      tenantNumber: `${tenant?.lodasExportTenant || ''}`,
      salaryTypeTaxFree: `${tenant?.lodasSalaryTypeTaxFree || ''}`,
      salaryTypeTaxable: `${tenant?.lodasSalaryTypeTaxable || ''}`,
      salaryTypeSalaryReduction: `${tenant?.lodasSalaryTypeSalaryReduction || ''}`,
      salaryTypeMobilityTaxFree: `${tenant?.lodasSalaryTypeMobilityTaxFree || ''}`,
      salaryTypeMobilityTaxable: `${tenant?.lodasSalaryTypeMobilityTaxable || ''}`,
      salaryTypeCoupons: `${tenant?.lodasSalaryTypeCoupons || ''}`,
      salaryTypeGifts: `${tenant?.lodasSalaryTypeGifts || ''}`,
      salaryTypeIncentives: `${tenant?.lodasSalaryTypeIncentives || ''}`,
      salaryTypeInternet: `${tenant?.lodasSalaryTypeInternet || ''}`,
      salaryTypeRecreation: `${tenant?.lodasSalaryTypeRecreation || ''}`,
      salaryTypeFitness: `${tenant?.lodasSalaryTypeFitness || ''}`,
      enableAutomatedReports:
        (tenant.reportEmailList || []).length > 0 && benefits.every(benefit => tenant?.reportBenefitList?.includes(benefit)),
      enableDetailReports:
        (tenant.reportDetailBenefitList || []).length > 0 && benefits.every(benefit => tenant?.reportDetailBenefitList?.includes(benefit)),
      reportEmailList: tenant.reportEmailList?.length ? tenant.reportEmailList : getDefaultEmailList(),
    });
  }, [tenant, reset, getDefaultEmailList, benefits]);

  const updateTenant = useMutation({
    mutationFn: async (values: FormValues) => {
      await apis.tenants.updateTenant({
        tenantId: tenant!.id,
        updateTenantDTO: {
          lodasExportTenant: values.tenantNumber ? parseInt(values.tenantNumber, 10) : null,
          lodasExportConsultant: values.consultantNumber ? parseInt(values.consultantNumber, 10) : null,
          lodasSalaryTypeTaxFree: values.salaryTypeTaxFree ? parseInt(values.salaryTypeTaxFree, 10) : undefined,
          lodasSalaryTypeTaxable: values.salaryTypeTaxable ? parseInt(values.salaryTypeTaxable, 10) : undefined,
          lodasSalaryTypeSalaryReduction: values.salaryTypeSalaryReduction ? parseInt(values.salaryTypeSalaryReduction, 10) : undefined,
          lodasSalaryTypeMobilityTaxFree: values.salaryTypeMobilityTaxFree ? parseInt(values.salaryTypeMobilityTaxFree, 10) : undefined,
          lodasSalaryTypeMobilityTaxable: values.salaryTypeMobilityTaxable ? parseInt(values.salaryTypeMobilityTaxable, 10) : undefined,
          lodasSalaryTypeCoupons: values.salaryTypeCoupons ? parseInt(values.salaryTypeCoupons, 10) : undefined,
          lodasSalaryTypeGifts: values.salaryTypeGifts ? parseInt(values.salaryTypeGifts, 10) : undefined,
          lodasSalaryTypeIncentives: values.salaryTypeIncentives ? parseInt(values.salaryTypeIncentives, 10) : undefined,
          lodasSalaryTypeInternet: values.salaryTypeInternet ? parseInt(values.salaryTypeInternet, 10) : undefined,
          lodasSalaryTypeRecreation: values.salaryTypeRecreation ? parseInt(values.salaryTypeRecreation, 10) : undefined,
          lodasSalaryTypeFitness: values.salaryTypeFitness ? parseInt(values.salaryTypeFitness, 10) : undefined,
          reportEmailList: values.reportEmailList || undefined,
          reportBenefitList: updateArrayAll<TenantDTOReportBenefitListEnum>(
            benefits,
            values.enableAutomatedReports ? 'update' : 'delete',
            tenant?.reportBenefitList,
          ),
          reportDetailBenefitList: updateArrayAll<TenantDTOReportBenefitListEnum>(
            benefits,
            values.enableDetailReports ? 'update' : 'delete',
            tenant?.reportDetailBenefitList,
          ),
        },
      });
    },

    onSuccess: () => {
      invalidateTenant();
    },
  });

  const handleSave = useCallback(
    (values: FormValues) => {
      updateTenant.mutate(values);
    },
    [updateTenant],
  );

  const exportTypeDialog = useNewDialogState();

  const exportTypeFields = useExportTypeFields(benefits);

  const enableAutomatedReports = watch('enableAutomatedReports');

  return (
    <>
      <SettingsFormPanel
        title={t('settingsForm.title')}
        info={t('settingsForm.info', { benefit: benefits[0] })}
        isDirty={formState.isDirty}
        isLoading={updateTenant.isPending}
        onSubmit={handleSubmit(handleSave)}
        onReset={reset}
      >
        <SettingsFormRow
          fullWidth
          title={t('benefitsModule:settingsDialog.exportType')}
          info={t('benefitsModule:settingsDialog.exportTypeHint')}
        >
          {tenant?.lodasExportType ? (
            <>
              <Typography>{t('benefitsModule:settingsDialog.exportTypeLong')}:</Typography>
              <SelectedExportTypeLogo />
            </>
          ) : (
            <Button size="small" variant="contained" onClick={exportTypeDialog.handleOpen}>
              {t('benefitsModule:settingsDialog.selectExportType')}
            </Button>
          )}
        </SettingsFormRow>
        <SettingsFormRow
          fullWidth
          title={t('settingsForm.section.automatedReports.title')}
          info={t('settingsForm.section.automatedReports.info')}
        >
          <Stack spacing={1}>
            <Box>
              <CheckboxControl
                control={control}
                name="enableAutomatedReports"
                label={t('benefitsModule:report.successExportDialog.checkbox')}
              />
            </Box>
            <Box>
              {(benefits.includes(BenefitDTOBenefitEnum.Lunch) ||
                benefits.includes(BenefitDTOBenefitEnum.Mobility) ||
                benefits.includes(BenefitDTOBenefitEnum.Flex)) && (
                <CheckboxControl
                  control={control}
                  name="enableDetailReports"
                  label={t('benefitsModule:report.successExportDialog.detailCheckbox')}
                  disabled={!enableAutomatedReports}
                />
              )}
            </Box>
            <TextFieldChipsControl
              control={control}
              name="reportEmailList"
              rules={{ validate: { email: validateEmails } }}
              sx={{ width: '100%', marginBottom: 3 }}
              disabled={!enableAutomatedReports}
            />
          </Stack>
        </SettingsFormRow>
        {(exportTypeFields.includes(ExportTypeFields.ConsultantNumber) || exportTypeFields.includes(ExportTypeFields.TenantNumber)) && (
          <SettingsFormRow
            fullWidth
            title={t('settingsForm.section.tenantConsultantNumber.title')}
            info={t('settingsForm.section.tenantConsultantNumber.info')}
          >
            <Stack spacing={1.5}>
              {exportTypeFields.includes(ExportTypeFields.ConsultantNumber) && (
                <TextFieldControl
                  fullWidth
                  helperText={t('consultantNumberHelper')}
                  control={control}
                  name={ExportTypeFields.ConsultantNumber}
                  label={t(ExportTypeFields.ConsultantNumber)}
                  rules={{ pattern: /^\d+$/, required: true }}
                />
              )}
              {exportTypeFields.includes(ExportTypeFields.TenantNumber) && (
                <TextFieldControl
                  fullWidth
                  helperText={t('tenantNumberHelper')}
                  control={control}
                  name={ExportTypeFields.TenantNumber}
                  label={t(ExportTypeFields.TenantNumber)}
                  rules={{ pattern: /^\d+$/, required: true }}
                />
              )}
            </Stack>
          </SettingsFormRow>
        )}
        <SettingsFormRow fullWidth title={t('settingsForm.section.salaryTypes.title')} info={t('settingsForm.section.salaryTypes.info')}>
          <Stack spacing={1.5}>
            {showBenefitHeaders &&
              (exportTypeFields.includes(ExportTypeFields.SalaryTypeTaxFree) ||
                exportTypeFields.includes(ExportTypeFields.SalaryTypeTaxable)) && (
                <Typography variant="h3" gutterBottom>
                  {t('common:benefit.LUNCH')}
                </Typography>
              )}
            {exportTypeFields.includes(ExportTypeFields.SalaryTypeTaxFree) && (
              <TextFieldControl
                fullWidth
                helperText={t('salaryTypeTaxFreeHelper')}
                control={control}
                rules={{ maxLength: 4, pattern: /^\d+$/, required: true }}
                name={ExportTypeFields.SalaryTypeTaxFree}
                label={t(ExportTypeFields.SalaryTypeTaxFree)}
              />
            )}
            {exportTypeFields.includes(ExportTypeFields.SalaryTypeTaxable) && (
              <TextFieldControl
                fullWidth
                helperText={t('salaryTypeTaxableHelper')}
                control={control}
                rules={{ maxLength: 4, pattern: /^\d+$/ }}
                name={ExportTypeFields.SalaryTypeTaxable}
                label={t(ExportTypeFields.SalaryTypeTaxable)}
              />
            )}
            {showBenefitHeaders &&
              (exportTypeFields.includes(ExportTypeFields.SalaryTypeMobilityTaxFree) ||
                exportTypeFields.includes(ExportTypeFields.SalaryTypeMobilityTaxable)) && (
                <Typography variant="h3" gutterBottom>
                  {t('common:benefit.MOBILITY')}
                </Typography>
              )}
            {exportTypeFields.includes(ExportTypeFields.SalaryTypeMobilityTaxFree) && (
              <TextFieldControl
                fullWidth
                helperText={t('salaryTypeMobilityTaxFreeHelper')}
                control={control}
                rules={{ maxLength: 4, pattern: /^\d+$/, required: true }}
                name={ExportTypeFields.SalaryTypeMobilityTaxFree}
                label={t(ExportTypeFields.SalaryTypeMobilityTaxFree)}
              />
            )}
            {exportTypeFields.includes(ExportTypeFields.SalaryTypeMobilityTaxable) && (
              <TextFieldControl
                fullWidth
                helperText={t('salaryTypeMobilityTaxableHelper')}
                control={control}
                rules={{ maxLength: 4, pattern: /^\d+$/ }}
                name={ExportTypeFields.SalaryTypeMobilityTaxable}
                label={t(ExportTypeFields.SalaryTypeMobilityTaxable)}
              />
            )}
            {showBenefitHeaders &&
              (exportTypeFields.includes(ExportTypeFields.SalaryTypeCoupons) ||
                exportTypeFields.includes(ExportTypeFields.SalaryTypeGifts)) && (
                <Typography variant="h3" gutterBottom>
                  {t('common:benefit.COUPONS')}
                </Typography>
              )}
            {exportTypeFields.includes(ExportTypeFields.SalaryTypeCoupons) && (
              <TextFieldControl
                fullWidth
                helperText={t('salaryTypeCouponsHelper')}
                control={control}
                rules={{ maxLength: 4, pattern: /^\d+$/, required: true }}
                name={ExportTypeFields.SalaryTypeCoupons}
                label={t(ExportTypeFields.SalaryTypeCoupons)}
              />
            )}
            {exportTypeFields.includes(ExportTypeFields.SalaryTypeGifts) && (
              <TextFieldControl
                fullWidth
                helperText={t('salaryTypeGiftsHelper')}
                control={control}
                rules={{ maxLength: 4, pattern: /^\d+$/ }}
                name={ExportTypeFields.SalaryTypeGifts}
                label={t(ExportTypeFields.SalaryTypeGifts)}
              />
            )}
            {exportTypeFields.includes(ExportTypeFields.SalaryTypeFitness) && (
              <TextFieldControl
                fullWidth
                helperText={t('salaryTypeFitnessHelper')}
                control={control}
                rules={{ maxLength: 4, pattern: /^\d+$/, required: true }}
                name={ExportTypeFields.SalaryTypeFitness}
                label={t(ExportTypeFields.SalaryTypeFitness)}
              />
            )}
            {exportTypeFields.includes(ExportTypeFields.SalaryTypeIncentives) && (
              <TextFieldControl
                fullWidth
                helperText={t('salaryTypeIncentivesHelper')}
                control={control}
                rules={{ maxLength: 4, pattern: /^\d+$/ }}
                name={ExportTypeFields.SalaryTypeIncentives}
                label={t(ExportTypeFields.SalaryTypeIncentives)}
              />
            )}
            {showBenefitHeaders && exportTypeFields.includes(ExportTypeFields.SalaryTypeInternet) && (
              <Typography variant="h3" gutterBottom>
                {t('common:benefit.INTERNET')}
              </Typography>
            )}
            {exportTypeFields.includes(ExportTypeFields.SalaryTypeInternet) && (
              <TextFieldControl
                fullWidth
                helperText={t('salaryTypeInternetHelper')}
                control={control}
                rules={{ maxLength: 4, pattern: /^\d+$/, required: true }}
                name={ExportTypeFields.SalaryTypeInternet}
                label={t(ExportTypeFields.SalaryTypeInternet)}
              />
            )}
            {showBenefitHeaders && exportTypeFields.includes(ExportTypeFields.SalaryTypeRecreation) && (
              <Typography variant="h3" gutterBottom>
                {t('common:benefit.RECREATION')}
              </Typography>
            )}
            {exportTypeFields.includes(ExportTypeFields.SalaryTypeRecreation) && (
              <TextFieldControl
                fullWidth
                helperText={t('salaryTypeRecreationHelper')}
                control={control}
                rules={{ maxLength: 4, pattern: /^\d+$/, required: true }}
                name={ExportTypeFields.SalaryTypeRecreation}
                label={t(ExportTypeFields.SalaryTypeRecreation)}
              />
            )}
          </Stack>
        </SettingsFormRow>
      </SettingsFormPanel>
      <PayrollReportChooseExportTypeDialog benefits={benefits} dialogState={exportTypeDialog.dialogState} />
    </>
  );
};
