import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Checkbox, Tooltip, Typography } from '@mui/material';
import {
  BenefitDTOBenefitEnum,
  CouponBenefitDetailsDTOAlternateBenefitsEnum,
  FitnessBenefitOptionsDTOFitnessBudgetEnum,
} from 'probonio-shared-ui/api';
import { useTenant } from 'probonio-shared-ui/module/me';
import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export const FitnessFields: React.FC = () => {
  const { setValue, watch } = useFormContext();

  const benefitOptions: { monthlyBudget?: number; alternateBenefits?: CouponBenefitDetailsDTOAlternateBenefitsEnum[] } | undefined =
    watch('benefitOptions');
  const { tenant } = useTenant();
  const { t } = useTranslation('benefitsModule');
  const [isFitnessGranted, setIsFitnessGranted] = useState<boolean>(!!benefitOptions?.alternateBenefits?.length);

  const hasTenantFitness = tenant?.activeBenefits?.includes(BenefitDTOBenefitEnum.Fitness);
  const isFlex = tenant?.activeBenefits?.includes(BenefitDTOBenefitEnum.Flex);

  const isAmountPossible = Object.values(FitnessBenefitOptionsDTOFitnessBudgetEnum).some((amount: number) => {
    const monthlyBudget = benefitOptions?.monthlyBudget ?? 0;
    return isFlex ? monthlyBudget * 100 >= amount : monthlyBudget === amount;
  });

  const checked = useMemo(() => isFitnessGranted && isAmountPossible, [isAmountPossible, isFitnessGranted]);

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement> | undefined, value: boolean): void => {
    setIsFitnessGranted(value);
  }, []);

  useEffect(() => {
    setValue('benefitOptions.alternateBenefits', checked ? [BenefitDTOBenefitEnum.Fitness] : []);
  }, [checked, handleChange, isAmountPossible, isFitnessGranted, setValue]);

  return hasTenantFitness ? (
    <Box display="flex" alignItems="center">
      <Checkbox checked={checked} disabled={!isAmountPossible} onChange={handleChange} />
      <Typography>{t('FITNESS.grantBenefit')}</Typography>

      <Tooltip title={t('FITNESS.amountHint')}>
        <InfoOutlinedIcon color="action" fontSize="small" sx={{ ml: 0.5 }}></InfoOutlinedIcon>
      </Tooltip>
    </Box>
  ) : null;
};
