import { BenefitDTOBenefitEnum } from 'probonio-shared-ui/api';
import { useTenant } from 'probonio-shared-ui/module/me';
import { useMemo } from 'react';

export enum ExportTypeFields {
  ConsultantNumber = 'consultantNumber',
  TenantNumber = 'tenantNumber',
  SalaryTypeTaxFree = 'salaryTypeTaxFree',
  SalaryTypeTaxable = 'salaryTypeTaxable',
  ExportMonth = 'exportMonth',
  ProcessingMonth = 'processingMonth',
  SalaryTypeSalaryReduction = 'salaryTypeSalaryReduction',
  SalaryTypeMobilityTaxFree = 'salaryTypeMobilityTaxFree',
  SalaryTypeMobilityTaxable = 'salaryTypeMobilityTaxable',
  SalaryTypeCoupons = 'salaryTypeCoupons',
  SalaryTypeGifts = 'salaryTypeGifts',
  SalaryTypeIncentives = 'salaryTypeIncentives',
  SalaryTypeInternet = 'salaryTypeInternet',
  SalaryTypeRecreation = 'salaryTypeRecreation',
  SalaryTypeFitness = 'salaryTypeFitness',
  FlexBenefitsToInclude = 'flexBenefitsToInclude',
}

export const EXPORT_TYPE_CONFIG: Record<string, string[]> = {
  abs: [],
  addison: [ExportTypeFields.TenantNumber, ExportTypeFields.ProcessingMonth],
  agenda: [ExportTypeFields.TenantNumber, ExportTypeFields.ProcessingMonth],
  edlohn: [],
  lexware: [ExportTypeFields.ProcessingMonth],
  lodas: [ExportTypeFields.ConsultantNumber, ExportTypeFields.TenantNumber, ExportTypeFields.ProcessingMonth],
  'datev-lg': [ExportTypeFields.ConsultantNumber, ExportTypeFields.TenantNumber, ExportTypeFields.ProcessingMonth],
  sage: [ExportTypeFields.TenantNumber, ExportTypeFields.ProcessingMonth],
  sap: [],
  spdata: [ExportTypeFields.TenantNumber, ExportTypeFields.ProcessingMonth],
  generic: [ExportTypeFields.ProcessingMonth],
  custom_linde: [],
  custom_lufthansa: [ExportTypeFields.ProcessingMonth],
  custom_bertelsmann: [ExportTypeFields.ProcessingMonth],
};

export const EXPORT_BENEFIT_CONFIG: Partial<Record<BenefitDTOBenefitEnum, string[]>> = {
  LUNCH: [ExportTypeFields.SalaryTypeTaxFree, ExportTypeFields.SalaryTypeTaxable], // , ExportTypeFields.SalaryTypeSalaryReduction - currently deactivated due to regulatory issues
  MOBILITY: [ExportTypeFields.SalaryTypeMobilityTaxFree, ExportTypeFields.SalaryTypeMobilityTaxable],
  COUPONS: [ExportTypeFields.SalaryTypeCoupons],
  COUPONS_V2: [ExportTypeFields.SalaryTypeCoupons, ExportTypeFields.SalaryTypeGifts, ExportTypeFields.SalaryTypeIncentives],
  GIFTS: [ExportTypeFields.SalaryTypeGifts],
  INTERNET: [ExportTypeFields.SalaryTypeInternet],
  RECREATION: [ExportTypeFields.SalaryTypeRecreation],
  FITNESS: [ExportTypeFields.SalaryTypeFitness],
  FLEX: [ExportTypeFields.FlexBenefitsToInclude],
};

export function useExportTypeFields(benefits: BenefitDTOBenefitEnum[]): string[] {
  const { tenant } = useTenant();
  const exportType = tenant?.lodasExportType;

  return useMemo(() => {
    // for FLEX, we also need to add GIFTS if COUPONS if active
    const flexBenefits = [...(tenant?.flexBenefits || [])];
    if (flexBenefits.includes(BenefitDTOBenefitEnum.Coupons)) {
      flexBenefits.push(BenefitDTOBenefitEnum.Gifts);
    }

    return [
      ...(exportType ? EXPORT_TYPE_CONFIG[exportType] : []),
      ...benefits.flatMap(benefit => EXPORT_BENEFIT_CONFIG[benefit] || []),
      ...(benefits.includes(BenefitDTOBenefitEnum.Flex)
        ? flexBenefits.flatMap(flexBenefit => EXPORT_BENEFIT_CONFIG[flexBenefit] || [])
        : []),
    ];
  }, [benefits, exportType, tenant?.flexBenefits]);
}
