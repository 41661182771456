import { UseQueryResult } from '@tanstack/react-query';
import { EmployeeApiListEmployeeIdsRequest, EmployeeIdListDTO } from 'probonio-shared-ui/api';
import { apis, useTenantQuery } from 'probonio-shared-ui/module/api';

export function useEmployeeIdList(
  parameters?: Partial<EmployeeApiListEmployeeIdsRequest>,
  options?: { enabled?: boolean; staleTime?: number },
): UseQueryResult<EmployeeIdListDTO, Error> {
  return useTenantQuery(
    ['employees', parameters, apis.employee.listEmployeeIds.name],
    tenantId =>
      apis.employee
        .listEmployeeIds({
          tenantId,
          ...parameters,
        })
        .then(res => res.data),
    {
      staleTime: Infinity,
      ...options,
    },
  );
}
