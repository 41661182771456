import { BenefitDTOBenefitEnum } from 'probonio-shared-ui/api';
import { useTenant } from 'probonio-shared-ui/module/me';
import { useTranslation } from 'react-i18next';
import { BenefitTab } from '../../component/benefitTabs/BenefitTab';
import { BenefitTabPanel } from '../../component/benefitTabs/BenefitTabPanel';
import { useSearchParamsTabsState } from '../../component/tab/tabState';
import { BENEFIT_ICONS } from '../../module/benefits/BenefitIcon';
import BenefitSubPage from '../../module/benefits/BenefitSubPage';
import { CouponBudgetPanel } from '../../module/benefits/coupons/CouponBudgetPanel';
import { CouponOrderHistoryList } from '../../module/benefits/coupons/CouponOrderHistoryList';
import { FitnessTransactionHistoryList } from '../../module/benefits/fitness/FitnessTransactionHistoryList';
import { AuthorizedLayout } from '../../module/navigation/AuthorizedLayout';

export const CouponBudgetPage: React.FC = () => {
  const { t } = useTranslation('couponsModule');
  const { tenant } = useTenant();
  const tabsState = useSearchParamsTabsState('tab');

  const hasCouponsBenefit = tenant?.activeBenefits?.includes(BenefitDTOBenefitEnum.Coupons);
  const hasGiftsBenefit = tenant?.activeBenefits?.includes(BenefitDTOBenefitEnum.Gifts);
  const hasFitnessBenefit = tenant?.activeBenefits?.includes(BenefitDTOBenefitEnum.Fitness);

  return (
    <AuthorizedLayout
      title={t('budgetPage.subTitle')}
      breadcrumbs={[{ title: t('benefitsModule:COUPONS.title'), to: '/benefits/coupons' }]}
    >
      <BenefitSubPage
        icon={BENEFIT_ICONS.COUPONS}
        title={t('benefitsModule:COUPONS.title')}
        subTitle={t('budgetPage.subTitle')}
        menu={<CouponBudgetPanel />}
      >
        <BenefitTabPanel tabsState={tabsState}>
          <BenefitTab value="" label={t('employeeTabs.allTransactions')}>
            <CouponOrderHistoryList />
          </BenefitTab>
          {hasCouponsBenefit && (
            <BenefitTab value="couponOrders" label={t('employeeTabs.couponOrders')}>
              <CouponOrderHistoryList filter="COUPON_ORDERS" />
            </BenefitTab>
          )}
          {hasGiftsBenefit && (
            <BenefitTab value="gifts" label={t('employeeTabs.gifts')}>
              <CouponOrderHistoryList filter="GIFTS" />
            </BenefitTab>
          )}
          {hasFitnessBenefit && (
            <BenefitTab value="fitness" label={t('employeeTabs.fitness')}>
              <FitnessTransactionHistoryList />
            </BenefitTab>
          )}
          <BenefitTab value="budgetOrder" label={t('employeeTabs.budgetOrders')}>
            <CouponOrderHistoryList filter="BUDGET_ORDERS" />
          </BenefitTab>
        </BenefitTabPanel>
      </BenefitSubPage>
    </AuthorizedLayout>
  );
};
