import { Box, Typography, useTheme } from '@mui/material';
import { DateTime } from 'luxon';
import { MRT_Cell, MRT_ColumnDef, MRT_Row, MRT_TableInstance } from 'material-react-table';
import { FitnessTransactionHistoryDTO } from 'probonio-shared-ui/api';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { renderUserName } from '../../userManagement/userFormatter';
import { useAggregationContext } from '../coupons/aggregationContext';

interface CellProps {
  cell: MRT_Cell<FitnessTransactionHistoryDTO>;
  row: MRT_Row<FitnessTransactionHistoryDTO>;
  table: MRT_TableInstance<FitnessTransactionHistoryDTO>;
}

const AmountCell = ({ cell, row }: CellProps): JSX.Element => {
  const { t } = useTranslation('couponsModule');
  const theme = useTheme();

  const color = (cell.getValue() as number) >= 0 ? theme.palette.success.main : theme.palette.error.main;

  return (
    <Typography component="span" variant="inherit" color={color}>
      {t('common:money', { money: cell.renderValue() })}
    </Typography>
  );
};
const DateCell = ({ cell, table }: CellProps): JSX.Element => {
  const { i18n } = useTranslation('couponsModule');

  return (
    <Box marginLeft={table.getState().grouping[0] === 'month' ? 1 : 0}>
      {cell.renderValue() ? i18n.format(DateTime.fromISO(cell.getValue<string>()), 'date') : undefined}
    </Box>
  );
};

const AggregatedAmountCell = ({ aggregation, row }: CellProps & { aggregation: string }): JSX.Element => {
  const { t } = useTranslation('couponsModule');
  const theme = useTheme();
  const aggregationBuckets = useAggregationContext()[aggregation];
  const bucket = aggregationBuckets?.find(bucket => bucket.bucketKey === row.groupingValue);

  if (!bucket) {
    return <></>;
  }

  const color = (bucket.value as number) >= 0 ? theme.palette.success.main : theme.palette.error.main;

  return (
    <Typography component="span" variant="inherit" color={color}>
      {t('common:money', { money: bucket.value })}
    </Typography>
  );
};

export function useFitnessTransactionColumns(): MRT_ColumnDef<FitnessTransactionHistoryDTO>[] {
  const { t } = useTranslation('couponsModule');

  return useMemo<MRT_ColumnDef<FitnessTransactionHistoryDTO>[]>(
    () => [
      {
        id: 'month',
        accessorFn: transaction => DateTime.fromISO(transaction.effectiveDate).toFormat('yyyy-MM'),
        header: t('ordersTable.header.date'),
      },
      {
        accessorKey: 'effectiveDate',
        header: t('ordersTable.header.date'),
        Cell: DateCell,
        aggregationFn: 'max',
        AggregatedCell: ({ cell }) => <b>{DateTime.fromISO(cell.getValue<string>()).toLocaleString({ month: 'long', year: 'numeric' })}</b>,
      },
      {
        id: 'employeeName',
        accessorFn: transaction =>
          renderUserName(
            { firstName: transaction.employeeFirstName, lastName: transaction.employeeLastName, mail: transaction.employeeMail },
            undefined,
            transaction.employeeInactive,
          ),
        header: t('ordersTable.header.employee'),
        enableSorting: false,
      },
      {
        accessorKey: 'amount',
        header: t('ordersTable.header.amount'),
        Cell: AmountCell,
        AggregatedCell: props => (
          <b>
            <AggregatedAmountCell aggregation="amountByMonth" {...props} />
          </b>
        ),
      },
    ],
    [t],
  );
}
