import useDebounce from 'probonio-shared-ui/utils/useDebounce';
import { ChangeEventHandler, useCallback, useState } from 'react';
import { SearchInput } from '../../../component/form/SearchInput';
import { HorizontalBox, VerticalBox } from '../../../component/layout/BoxLayout';
import { EmployeeClearFilterButton } from '../employeeStatusList/EmployeeClearFilterButton';
import { FitnessTransactionTable } from './FitnessTransactionTable';

export const FitnessTransactionHistoryList: React.FC = () => {
  const [filterValue, setFilterValue] = useState('');
  const debouncedFilterValue = useDebounce(filterValue);
  const handleChangeFilter = useCallback<ChangeEventHandler<HTMLInputElement>>(ev => {
    setFilterValue(ev.target.value);
  }, []);

  const handleRevertFilters = useCallback(() => {
    setFilterValue('');
  }, []);

  return (
    <VerticalBox>
      <HorizontalBox sx={{ marginBottom: 1.5 }}>
        <HorizontalBox sx={{ flexGrow: 1 }} gap={1}>
          <SearchInput size="small" value={filterValue} onChange={handleChangeFilter} />
          {filterValue && <EmployeeClearFilterButton onRevertFilters={handleRevertFilters} />}
        </HorizontalBox>
      </HorizontalBox>
      <FitnessTransactionTable filter={debouncedFilterValue} />
    </VerticalBox>
  );
};
