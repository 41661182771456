import { Box } from '@mui/material';
import { MRT_SortingState } from 'material-react-table';
import { FitnessTransactionHistoryDTO } from 'probonio-shared-ui/api';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InfiniteScrollReactTable } from '../../../component/table/InfiniteScrollReactTable';
import { mapSortModel } from '../../../component/table/sortMapper';
import { useLoadMore } from '../../../component/table/useLoadMore';
import { AggregationContextProvider, AggregationsModel } from '../coupons/aggregationContext';

import { useFitnessTransactionColumns } from './useFitnessTransactionColumns';
import { useInfiniteFitnessTransactionHistory } from './useInfiniteFitnessTransactionHistory';

interface Props {
  filter?: string;
}

const OrdersTableComp: React.FC<Props> = ({ filter }) => {
  const { t } = useTranslation('couponsModule');
  const [sortModel, setSortModel] = useState<MRT_SortingState>([{ id: 'effectiveDate', desc: true }]);
  const isSortedByDate = sortModel.length === 0 || sortModel[0].id === 'effectiveDate';
  const columns = useFitnessTransactionColumns();
  const filteredColumns = columns.filter(col => isSortedByDate || col.id !== 'month');

  const mappedSortModel = mapSortModel(columns, sortModel);

  const queryResult = useInfiniteFitnessTransactionHistory(pageParam => ({
    sortBy: mappedSortModel,
    filter,
    withAggregations: pageParam === 0 && isSortedByDate,
  }));

  const loadMoreProps = useLoadMore(queryResult, 'fitnessTransactions');

  return (
    <Box>
      <AggregationContextProvider
        value={(queryResult.data?.pages[0].aggregations || { amountByMonth: [] }) as unknown as AggregationsModel}
      >
        <InfiniteScrollReactTable<FitnessTransactionHistoryDTO>
          {...loadMoreProps}
          columns={filteredColumns}
          enablePagination={false}
          enableTopToolbar={false}
          enableBottomToolbar={false}
          enableColumnActions={false}
          enableSortingRemoval={false}
          localization={{ noRecordsToDisplay: t('ordersTable.noResultsFitness') }}
          manualSorting
          onSortingChange={setSortModel}
          state={{ sorting: sortModel, grouping: isSortedByDate ? ['month'] : [], expanded: true }}
          groupedColumnMode="remove"
        />
      </AggregationContextProvider>
    </Box>
  );
};

export const FitnessTransactionTable = React.memo(OrdersTableComp);
