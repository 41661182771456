import { Divider, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { HRSystemIntegrationForm } from './HRSystemIntegrationForm';

export const HRSystemPanel: React.FC = () => {
  const { t } = useTranslation('tenantModule');

  return (
    <>
      <Grid container marginTop={1} flexWrap="nowrap" alignItems="stretch">
        <Grid flexGrow={1}>
          <Typography variant="h2" gutterBottom>
            {t('hrSystem.title')}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {t('hrSystem.info')}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            <Trans i18nKey="tenantModule:hrSystem.guide.advancedTutorial">
              <a href="https://support.probonio.de/hc/de/articles/22930657574162-Personio-Integration" target="_blank" rel="noreferrer"></a>
            </Trans>
          </Typography>
        </Grid>
      </Grid>
      <Divider sx={{ marginY: 3 }} />
      <Grid container marginTop={1} flexWrap="nowrap">
        <Grid size={6}>
          <Typography variant="h3" gutterBottom>
            {t('hrSystem.guide.title')}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            <Trans i18nKey="tenantModule:hrSystem.guide.info" />
          </Typography>
          <Typography variant="h3" marginTop={3}>
            {t('hrSystem.guide.section1Title')}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            <Trans i18nKey="tenantModule:hrSystem.guide.section1Text" />
          </Typography>
        </Grid>
        <Grid>
          <Divider orientation="vertical" sx={{ marginX: 3 }} />
        </Grid>
        <Grid size={7} display="flex" alignItems="center">
          <HRSystemIntegrationForm />
        </Grid>
      </Grid>
    </>
  );
};
