import { Box, InputAdornment, Stack, Typography } from '@mui/material';
import {
  BenefitActivationDTO,
  BenefitDTOBenefitEnum,
  BenefitTemplateItemDTO,
  CouponsBenefitOptionsDTOAlternateBenefitsEnum,
  CreateBenefitActivationDTO,
  LunchBenefitOptionsDTOTaxationTypeEnum,
  MobilityBenefitOptionsDTOTaxationTypeEnum,
  UpdateBenefitActivationDTO,
} from 'probonio-shared-ui/api';
import { RadioControl } from 'probonio-shared-ui/component/form';
import { apis, useTenantQuery } from 'probonio-shared-ui/module/api';
import { useTenant } from 'probonio-shared-ui/module/me';
import React, { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { TextFieldControl } from '../../../component/form';
import { ActivationDialogExtensions } from '../ActivationDialog';
import { NonCashLimitWarning } from '../ActivationDialog/NonCashLimitWarning';
import { AvailableCouponsAccordion } from '../coupons/AvailableCouponsDrawer';
import { FitnessFields } from '../fitness/FitnessFields';

export interface FlexBenefitOptions {
  monthlyBudget: number;
  lunchTaxationType: LunchBenefitOptionsDTOTaxationTypeEnum;
  mobilityTaxationType: MobilityBenefitOptionsDTOTaxationTypeEnum;
  alternateBenefits?: CouponsBenefitOptionsDTOAlternateBenefitsEnum[];
}

const COUPON_MAX = 50;
const COUPON_STEP = 5;

function getCouponBudget(monthlyBudget: number): number {
  // round to 5€ steps
  return Math.min(COUPON_MAX, Math.trunc(monthlyBudget / COUPON_STEP) * COUPON_STEP) * 100;
}

const Header: React.FC = () => {
  const { tenant } = useTenant();
  const { watch } = useFormContext();
  const { employeeId } = useParams();
  const benefitOptions: FlexBenefitOptions = watch('benefitOptions');

  return (
    <>
      {tenant?.flexBenefits?.includes(BenefitDTOBenefitEnum.Coupons) && (
        <NonCashLimitWarning
          employeeId={employeeId}
          benefit={BenefitDTOBenefitEnum.Flex}
          monthlyBudget={getCouponBudget(benefitOptions.monthlyBudget!)}
        />
      )}
    </>
  );
};

const InlineField: React.FC = () => {
  const { t } = useTranslation('benefitsModule');
  const { control } = useFormContext();

  return (
    <TextFieldControl
      slotProps={{
        input: {
          endAdornment: <InputAdornment position="end">€</InputAdornment>,
        },
        htmlInput: { step: '5' },
      }}
      rules={{
        required: true,
        min: 5,
        max: 500,
      }}
      type="number"
      control={control}
      variant="outlined"
      name="benefitOptions.monthlyBudget"
      label={t('FLEX.fields.monthlyBudget')}
      margin="dense"
      fullWidth
    />
  );
};

const Fields: React.FC = () => {
  const { t } = useTranslation('benefitsModule');
  const { control, watch } = useFormContext();
  const { tenant } = useTenant();

  const benefitOptions: FlexBenefitOptions = watch('benefitOptions');
  const couponBudget = getCouponBudget(benefitOptions.monthlyBudget);

  const { data, isLoading } = useTenantQuery(
    ['benefits', 'coupons', 'definition', apis.coupons.findCouponDefinitions.name],
    tenantId => apis.coupons.findCouponDefinitions({ tenantId, sortBy: ['name:asc'] }).then(resp => resp.data),
    {
      enabled: tenant?.flexBenefits?.includes(BenefitDTOBenefitEnum.Coupons),
    },
  );

  return (
    <Stack spacing={2}>
      {tenant?.flexBenefits?.includes(BenefitDTOBenefitEnum.Lunch) && (
        <Box>
          <Typography variant="h3" gutterBottom>
            {t('common:benefit.LUNCH')}
          </Typography>
          <RadioControl
            control={control}
            name="benefitOptions.lunchTaxationType"
            options={[
              {
                label: t('LUNCH.fields.taxationType.FLAT_RATE_TAXATION'),
                value: LunchBenefitOptionsDTOTaxationTypeEnum.FlatRateTaxation,
              },
              {
                label: t('LUNCH.fields.taxationType.SIMPLE_TAXATION'),
                value: LunchBenefitOptionsDTOTaxationTypeEnum.SimpleTaxation,
              },
            ]}
          />
        </Box>
      )}
      {tenant?.flexBenefits?.includes(BenefitDTOBenefitEnum.Mobility) && (
        <Box>
          <Typography variant="h3" gutterBottom>
            {t('common:benefit.MOBILITY')}
          </Typography>
          <RadioControl
            control={control}
            name="benefitOptions.mobilityTaxationType"
            options={[
              {
                label: t('MOBILITY.fields.taxationType.TAXABLE'),
                value: MobilityBenefitOptionsDTOTaxationTypeEnum.Taxable,
              },
              {
                label: t('MOBILITY.fields.taxationType.TAX_FREE'),
                value: MobilityBenefitOptionsDTOTaxationTypeEnum.TaxFree,
              },
            ]}
          />
        </Box>
      )}
      {!isLoading && !!couponBudget && tenant?.flexBenefits?.includes(BenefitDTOBenefitEnum.Coupons) && (
        <Box mb={2}>
          <AvailableCouponsAccordion coupons={data?.coupons} amount={couponBudget} showAmount />
        </Box>
      )}
      <Box>
        <FitnessFields></FitnessFields>
      </Box>
    </Stack>
  );
};

export function useFlexDialogExtension(): ActivationDialogExtensions<FlexBenefitOptions> {
  const { tenant, invalidateTenant } = useTenant();

  const handleResetBenefitOptions = useCallback(
    (selectedActivation?: BenefitActivationDTO | BenefitTemplateItemDTO): FlexBenefitOptions => {
      return {
        monthlyBudget: selectedActivation?.flexOptions ? selectedActivation.flexOptions.monthlyBudget / 100 : 100,
        lunchTaxationType: selectedActivation?.flexOptions
          ? selectedActivation.flexOptions.lunchTaxationType
          : tenant?.lunchTaxationType || LunchBenefitOptionsDTOTaxationTypeEnum.FlatRateTaxation,
        mobilityTaxationType: selectedActivation?.flexOptions?.mobilityTaxationType || MobilityBenefitOptionsDTOTaxationTypeEnum.Taxable,
        alternateBenefits: selectedActivation?.flexOptions?.alternateBenefits,
      };
    },
    [tenant?.lunchTaxationType],
  );

  const mapFlexOptions = useCallback(
    (benefitOptions: FlexBenefitOptions) => ({
      monthlyBudget: Math.round(benefitOptions.monthlyBudget * 100),
      lunchTaxationType: benefitOptions.lunchTaxationType,
      mobilityTaxationType: benefitOptions.mobilityTaxationType,
      alternateBenefits: benefitOptions?.alternateBenefits ?? [],
    }),
    [],
  );

  const handleCreateActivation = useCallback<ActivationDialogExtensions<FlexBenefitOptions>['onCreateActivation']>(
    async (baseValues, benefitOptions, saveActivation) => {
      const create: CreateBenefitActivationDTO = {
        ...baseValues,
        benefit: BenefitDTOBenefitEnum.Flex,
        flexOptions: mapFlexOptions(benefitOptions),
      };
      await saveActivation(create);

      if (tenant?.lunchTaxationType !== benefitOptions.lunchTaxationType) {
        await apis.tenants.updateTenant({ tenantId: tenant!.id, updateTenantDTO: { lunchTaxationType: benefitOptions.lunchTaxationType } });
        invalidateTenant();
      }
    },
    [invalidateTenant, mapFlexOptions, tenant],
  );

  const handleUpdateActivation = useCallback<NonNullable<ActivationDialogExtensions<FlexBenefitOptions>['onUpdateActivation']>>(
    async (baseValues, benefitOptions, saveActivation) => {
      const update: UpdateBenefitActivationDTO = {
        ...baseValues,
        flexOptions: mapFlexOptions(benefitOptions),
      };
      await saveActivation(update);

      if (tenant?.lunchTaxationType !== benefitOptions.lunchTaxationType) {
        await apis.tenants.updateTenant({ tenantId: tenant!.id, updateTenantDTO: { lunchTaxationType: benefitOptions.lunchTaxationType } });
        invalidateTenant();
      }
    },
    [invalidateTenant, mapFlexOptions, tenant],
  );

  return useMemo(
    () => ({
      Header,
      InlineField,
      Fields,
      onResetBenefitOptions: handleResetBenefitOptions,
      onCreateActivation: handleCreateActivation,
      onUpdateActivation: handleUpdateActivation,
    }),
    [handleCreateActivation, handleResetBenefitOptions, handleUpdateActivation],
  );
}
